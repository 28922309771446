import type { ResOptions } from "@/types/http";
import APIS from "@/api";

export const characterService = {
  async getCharacterById(characterId: string) {
    return await useHttp.get<ResOptions<any>>("/api/character/detail", {
      characterId: +characterId,
    });
  },

  async getSystemCharacters(params: { page: number; size: number }) {
    return useHttp.get<ResOptions<any>>(APIS.GET_CHARACTERS, { ...params, origin: "pgc" });
  },

  async getUserCharacters(params: { page: number; size: number } = { page: 1, size: 100 }) {
    return useHttp.get<ResOptions<any>>(APIS.GET_CHARACTERS, { ...params, origin: "ugc" });
  },

  async getExploreCharacters(params: { page: number; size: number }) {
    return useHttp.get<ResOptions<any>>("/api/character/explore", { ...params });
  },
  async getHomepageCharacters() {
    return useHttp.get<ResOptions<any>>("/api/homepage");
  },

  async deleteCharacter(characterId: string) {
    return useHttp.post<ResOptions<any>>("/api/character/ugc/delete", { characterId: +characterId });
  },
};
